"use client";

import { ExtensionLoginButton } from "@multiversx/sdk-dapp/UI/extension/ExtensionLoginButton";
import { WalletConnectLoginButton } from "@multiversx/sdk-dapp/UI/walletConnect/WalletConnectLoginButton";

export default function WalletLogin(props: { callbackRoute: string }) {
  const commonProps = {
    callbackRoute: props.callbackRoute,
    nativeAuth: true,
  };

  return (
    <div className="grid grid-cols-1 gap-4 ">
      <ExtensionLoginButton
        loginButtonText="Extension"
        className="base-button"
        {...commonProps}
      />

      {/* <LedgerLoginButton
        loginButtonText='Ledger'
        className='base-button'
        showScamPhishingAlert={false}
        {...commonProps}
      /> */}

      <WalletConnectLoginButton
        loginButtonText="xPortal"
        className="base-button"
        modalClassName="base-modal"
        showScamPhishingAlert={false}
        {...commonProps}
        {...(process.env.NEXT_PUBLIC_WALLET_CONNECT_V2_PROJECT_ID
          ? {
              isWalletConnectV2: true,
            }
          : {})}
      />
    </div>
  );
}
