import styles from './styles.module.css'
export function Loader () {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <div className='flex items-center justify-center w-full'>
        <svg width="48" height="100" viewBox="0 0 83 173" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.leftLogo}>
          <path d="M32.8218 50.5745V51.0745H33.3218H53.845H82.1981V157.536C82.1981 161.47 80.6354 165.243 77.8539 168.024C75.0723 170.806 71.2998 172.368 67.3661 172.368H0.501894V15.9266H0.501955L0.501832 15.9188C0.470936 13.9454 0.831018 11.9854 1.56131 10.1518C2.29161 8.31826 3.37767 6.64742 4.75686 5.23565C6.13605 3.82388 7.78108 2.69912 9.59709 1.92622C11.4125 1.15357 13.3629 0.747846 15.3358 0.732422H32.8218V50.5745ZM32.7011 139.669V140.169H33.2011H47.3258C47.9537 140.169 48.5966 139.996 49.0972 139.663C49.6025 139.326 49.9989 138.794 49.9989 138.1V83.8944V83.3944H49.4989H40.8068C38.6791 83.3941 36.6367 84.2304 35.1203 85.7228C33.6038 87.2152 32.7349 89.244 32.7012 91.3714H32.7011V91.3793V139.669Z" stroke="#FFD10A"/>
        </svg>
        <div>
          <svg width="48" height="100" viewBox="0 0 84 173" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.topLogo}>
            <path d="M50.845 121.932V121.432H50.345H1.46875V15.332C1.46875 11.3983 3.0314 7.62573 5.81294 4.84419C8.59448 2.06265 12.3671 0.5 16.3007 0.5H83.1649V156.94C83.1501 158.933 82.7369 160.903 81.9497 162.734C81.1623 164.566 80.0165 166.221 78.5798 167.604C77.143 168.986 75.4443 170.067 73.5836 170.783C71.7229 171.499 69.7378 171.836 67.745 171.774L67.7371 171.774H67.7293H50.845V121.932ZM50.9657 32.9577V32.4577H50.4657H36.341C35.713 32.4577 35.0756 32.6304 34.5806 32.9421C34.0912 33.2503 33.6679 33.7486 33.6679 34.4064V88.8531V89.3531H34.1679H42.8601C45.0099 89.3531 47.0715 88.4991 48.5917 86.979C50.1118 85.4589 50.9657 83.3972 50.9657 81.2475V32.9577Z" stroke="#FFD10A"/>
          </svg>
          <svg width="48" height="100" viewBox="0 0 83 173" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.bottomLogo}>
            <path d="M32.8218 50.5745V51.0745H33.3218H53.845H82.1981V157.536C82.1981 161.47 80.6354 165.243 77.8539 168.024C75.0723 170.806 71.2998 172.368 67.3661 172.368H0.501894V15.9266H0.501955L0.501832 15.9188C0.470936 13.9454 0.831018 11.9854 1.56131 10.1518C2.29161 8.31826 3.37767 6.64742 4.75686 5.23565C6.13605 3.82388 7.78108 2.69912 9.59709 1.92622C11.4125 1.15357 13.3629 0.747846 15.3358 0.732422H32.8218V50.5745ZM32.7011 139.669V140.169H33.2011H47.3258C47.9537 140.169 48.5966 139.996 49.0972 139.663C49.6025 139.326 49.9989 138.794 49.9989 138.1V83.8944V83.3944H49.4989H40.8068C38.6791 83.3941 36.6367 84.2304 35.1203 85.7228C33.6038 87.2152 32.7349 89.244 32.7012 91.3714H32.7011V91.3793V139.669Z" stroke="#FFD10A"/>
          </svg>
        </div>
        <svg width="48" height="100" viewBox="0 0 84 173" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.rightLogo}>
          <path d="M50.845 121.932V121.432H50.345H1.46875V15.332C1.46875 11.3983 3.0314 7.62573 5.81294 4.84419C8.59448 2.06265 12.3671 0.5 16.3007 0.5H83.1649V156.94C83.1501 158.933 82.7369 160.903 81.9497 162.734C81.1623 164.566 80.0165 166.221 78.5798 167.604C77.143 168.986 75.4443 170.067 73.5836 170.783C71.7229 171.499 69.7378 171.836 67.745 171.774L67.7371 171.774H67.7293H50.845V121.932ZM50.9657 32.9577V32.4577H50.4657H36.341C35.713 32.4577 35.0756 32.6304 34.5806 32.9421C34.0912 33.2503 33.6679 33.7486 33.6679 34.4064V88.8531V89.3531H34.1679H42.8601C45.0099 89.3531 47.0715 88.4991 48.5917 86.979C50.1118 85.4589 50.9657 83.3972 50.9657 81.2475V32.9577Z" stroke="#FFD10A"/>
        </svg>
      </div>
    </div>
  )
}
