import { useGetAccountInfo } from './sdkDappHooks'
import { AuthFetchers } from '@fetchers/axios/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'

export const UserStatus = {
  NO_WALLET: 'NO_WALLET',
  CONNECTED: 'CONNECTED',
  SIGNED: 'SIGNED',
  REGISTERED: 'REGISTERED',
  KYC: 'KYC',
  VERIFIED: 'VERIFIED'
}

export const QUERY_KEYS = {
  signed: 'isSigned',
  registered: 'isRegistered',
  verified: 'isVerified'
}

// eslint-disable-next-line no-redeclare
export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus]

export function useUserStatus () {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [status, setStatus] = useState<UserStatus>(UserStatus.NO_WALLET)
  const { address } = useGetAccountInfo()
  const queryClient = useQueryClient()

  const {
    data: isSigned,
    error: signedError,
    isLoading: signedIsLoading
  } = useQuery({
    queryKey: [QUERY_KEYS.signed, address],
    queryFn: () => {
      if (!address) return false
      return AuthFetchers.isUserSigned()
    }
  })

  const {
    data: isRegistered,
    error: registeredError,
    isLoading: registeredIsLoading
  } = useQuery({
    queryKey: [QUERY_KEYS.registered, isSigned, address],
    queryFn: () => {
      if (!isSigned || !address) return false
      return AuthFetchers.isUserRegistered(address)
    },
    enabled: !signedIsLoading
  })

  const {
    data: isVerified,
    error: verifiedError,
    isLoading: verifiedIsLoading
  } = useQuery({
    queryKey: [QUERY_KEYS.verified, isRegistered, address],
    queryFn: () => {
      if (!address || !isRegistered) return false
      return AuthFetchers.isUserVerified(address)
    },
    enabled: !registeredIsLoading
  })

  const signedHasLoaded = useMemo(() => !signedIsLoading, [signedIsLoading])
  const registeredHasLoaded = useMemo(
    () => (signedHasLoaded ? !registeredIsLoading : true),
    [signedHasLoaded, registeredIsLoading]
  )
  const verifiedHasLoaded = useMemo(
    () => (registeredHasLoaded ? !verifiedIsLoading : true),
    [registeredHasLoaded, verifiedIsLoading]
  )

  const hasLoadedAll = useMemo(
    () =>
      hasLoaded && signedHasLoaded && registeredHasLoaded && verifiedHasLoaded,
    [hasLoaded, signedHasLoaded, registeredHasLoaded, verifiedHasLoaded]
  )

  function fetchUserData (params: {
    address: string
    isSigned?: boolean
    isRegistered?: boolean
    isVerified?: boolean
  }) {
    const { address, isSigned, isRegistered, isVerified } = params

    if (!address) {
      setStatus(UserStatus.NO_WALLET)
      setHasLoaded(true)
      return
    }

    if (!isSigned) {
      setStatus(UserStatus.CONNECTED)
      setHasLoaded(true)
      return
    }

    if (!isRegistered) {
      setStatus(UserStatus.SIGNED)
      setHasLoaded(true)
      return
    }

    if (!isVerified) {
      setStatus(UserStatus.REGISTERED)
      setHasLoaded(true)
      return
    }

    setStatus(UserStatus.VERIFIED)
    setHasLoaded(true)
  }

  useEffect(() => {
    if (signedError || registeredError || verifiedError) {
      setStatus(UserStatus.NO_WALLET)
      return
    }

    if (!signedIsLoading) {
      fetchUserData({ address, isSigned, isRegistered, isVerified })
    }

    return () => {
      setStatus(UserStatus.NO_WALLET)
    }
  }, [
    address,
    isSigned,
    signedError,
    signedIsLoading,
    isRegistered,
    registeredError,
    registeredIsLoading,
    isVerified,
    verifiedError,
    verifiedIsLoading
  ])

  return {
    status,
    hasLoaded: hasLoadedAll,
    refetch: () => {
      queryClient.invalidateQueries([QUERY_KEYS.signed, address])
    },
    address
  }
}
