import { SigninDto } from '@fetchers/dtos/Signin.dto'
import axiosInstance from '@lib/axios'

async function isUserSigned () {
  try {
    await axiosInstance.get('/auth/verify', { withCredentials: true })
    return true
  } catch {
    return false
  }
}

async function isUserRegistered (walletAddress: string) {
  try {
    const response = await axiosInstance.get<{ exists: boolean }>(
      '/users/exists',
      { params: { walletAddress } }
    )
    return response.data.exists
  } catch (error) {
    return false
  }
}

async function isUserVerified (walletAddress: string): Promise<boolean> {
  try {
    await axiosInstance.get('/users/is-verified', {
      params: {
        walletAddress
      },
      withCredentials: true
    })

    return true
  } catch (err) {
    return false
  }
}

async function signin (params: { dto: SigninDto }) {
  const { dto } = params

  try {
    const res = await axiosInstance.post('/auth/signin', dto, {
      withCredentials: true
    })

    return res
  } catch {
    return null
  }
}

async function signout () {
  try {
    const res = await axiosInstance.post(
      '/auth/logout',
      {},
      { withCredentials: true }
    )

    return res
  } catch {
    return null
  }
}

export const AuthFetchers = {
  isUserSigned,
  isUserRegistered,
  isUserVerified,
  signin,
  signout
}
