"use client";

import WalletLogin from "@components/WalletLogin";
import { Loader } from "@components/loader";
import { UserStatus, useUserStatus } from "@hooks/userStatusHook";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useRouter } from "next/navigation";

export default function LoginPage() {
  const { address } = useGetAccountInfo();
  const { status, hasLoaded } = useUserStatus();

  const router = useRouter();

  if (!hasLoaded) return <Loader />;
  if (address && status !== UserStatus.CONNECTED) {
    return router.push("/dashboard");
  }

  if (address) return router.push("/login/signing");

  return (
    <div className="h-full w-full">
      <h2 className="mb-6 text-lg">/ Connect with your MultiversX wallet</h2>
      <WalletLogin callbackRoute="/login/signing" />
    </div>
  );
}
